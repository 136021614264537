import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Card,
  CardContent,
  TextField,
  LinearProgress,
  Box,
  Alert,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { downloadFileFromS3 } from '../../api';
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";
// import { on } from 'events';


const ComplaintGenerator: React.FC = () => {
  const [prompt, setPrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { notification, showNotification, closeNotification } = useNotification();

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 2500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const examplePrompts = [
    {
      summary: 'Customer incurs a late payment fee due to mobile app issues',
      example: 'Generate a call between a customer service representative (CSR) working for Startup Bank and a customer who is calling to complain about issues with the mobile app which resulted in them being unable to pay their credit card bill and incurring a late payment fee. The CSR should not waive the late payment fee or provide any compensation during the call. The CSR should tell the customer that they will receive a response from the customer relations team in 2-5 working days.',
    },
    {
      summary: 'Customer experiences delay in transferring cash ISA from another provider',
      example: 'Generate a call between a customer service representative (CSR) working for Startup Bank and a customer who is calling to complain about delays in transferring their cash ISA from another provider to Startup Bank. The CSR should not provide any compensation during the call. The CSR should tell the customer that they will receive a response from the customer relations team in 2-5 working days.',
    },
    {
      summary: 'Customer loses their 0% APR benefit due to a missed payment which was the result of a family bereavement',
      example: 'Generate a call between a customer service representative (CSR) working for Startup Bank and a customer who is calling to complain about losing their 0% APR benefit as they missed a credit card payment due to their father recently passing away. The customer should request a waiver of the late payment fee, which the CSR should provide. The CSR should also additionally provide a temporary APR reduction back to 0% for 3 months and escalate the case to the customer relations team who will be in touch with the customer in 2-5 working days.'
    }
  ]
  const handleRequestFile = async () => {
    setLoading(true);
    showNotification('Generating complaint, please do not leave this page.', 'info');
    setError(null);

    try {
      const message = await downloadFileFromS3(prompt);
      if (message) {
        console.log(message);
        // showNotification(message, 'info', false);
        showNotification('Starting download, please check your downloads folder.', 'success', false);
      }
      setLoading(false);
    } catch (err) {
      setError('Failed to download file');
      showNotification('Failed to download file', 'error');
      setLoading(false);
    }
  };
  return (
    <Box maxWidth={"md"} mx={"auto"}>
      <Typography variant="h1" marginBottom="24px" gutterBottom>
        Complaint Generator
      </Typography>
        <Card variant="outlined">
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Box p={3}>
                <Typography variant="h4" gutterBottom>
                  Generate a sample call recording
                </Typography>


              </Box>
              <Divider />
            </Grid>
            <Grid item sm={12} py={3}>
              <Box px={3}>
                <Typography variant="body2" gutterBottom>
                  This feature allows you to generate a new call recording for demo purposes. Note that it can take up to a minute to generate the call recording. Once generated, the download will start automatically. Please do not leave this page while the call recording is being generated.
                </Typography>
                <TextField
                variant='filled'
                  label="Prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  margin="normal"
                  multiline
                  minRows={4}
                  fullWidth
                />
                <Button

                  variant="contained"
                  startIcon={<MicIcon />}
                  onClick={handleRequestFile}
                  disabled={loading || !prompt}
                  style={{ marginTop: '16px' }}
                >
                  {loading ? 'Generating...' : 'Generate'}
                </Button>
              </Box>
            </Grid>
          </Grid>
          {loading && <LinearProgress variant='determinate' value={progress} style={{ marginTop: '16px' }} />}
          {error && <Typography color="error">{error}</Typography>}
        </Card>
     

     <Box mt={4}>
        <Card variant="outlined" >
          <Grid container spacing={3}>
            <Grid item>
              <Box px={3} pt={3} mb={2}>
                <Typography variant="h6" gutterBottom>
                  Example Prompts
                </Typography>

                <Alert severity="info" variant='outlined'>
                <Typography variant="body2">
                  Below you can find a set of example prompts that can be used to generate a call recording using AI. If you want to use any of the examples, simply click the "Try Now" button to insert the prompt and then hit the "Generate" button.
                </Typography>
              </Alert>
              </Box>

      
            <Grid item sm={12} mx={3} mb={3}>
              <Grid container spacing={2} >
                {examplePrompts.map(({ summary, example }, index) => (

                  <Grid item sm={4} key={index}>
                    <Box key={index} >
                      <Card variant="outlined">
                        <Box p={2} minHeight={150}>
                          <Button
                            variant="outlined"
                            size='small'
                            onClick={() => setPrompt(example)}
                            disabled={loading}
                            style={{ marginBottom: "1em" }}
                            startIcon={<MicIcon />}
                          >
                            Add to prompt
                          </Button>
                          <Typography variant="subtitle2">{summary}</Typography>
                        </Box>

                        <Divider />
                        <CardContent
                          style={{
                            width: "100%",
                            minHeight: "250px",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                           
                          }}>
                          <Box my={2}>
                            <Typography variant="body2">{example}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            </Grid>
          </Grid>
        </Card>
      </Box>
     

      <Notification
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
        severity={notification.severity} />
    </Box>

  );
};
export default ComplaintGenerator;