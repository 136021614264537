import { useState, useEffect, useCallback, useRef } from "react";
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Box,
    Divider,
    IconButton,
    Grid,
    CircularProgress,
} from "@mui/material";

import { fetchAttachments } from "../../../api";
import useNotification from "../../../hooks/useNotification";

import RefreshIcon from "@mui/icons-material/Refresh";


interface File {
    name: string;
    type: string;
    // category: string;
    date: string;
    // url: string;
}

interface File {
    name: string;
    type: string;
    date: string;
}

interface Props {
    complaintId: string;
}

export const AttachedFiles: React.FC<Props> = ({ complaintId }) => {
    const { showNotification } = useNotification();
    const showNotificationRef = useRef(showNotification);
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [refreshKey, setRefreshKey] = useState<number>(0);

    const formatISODateTime = useCallback((date: Date): string => {
        const isoString = date.toISOString();
        const [isoDate, isoTime] = isoString.split('T');
        const time = isoTime.split('.')[0];
        return `${isoDate} ${time}`;
    }, []);

    useEffect(() => {
        showNotificationRef.current = showNotification;
    }, [showNotification]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                setLoading(true);
                const attachments = await fetchAttachments(complaintId);
                if (isMounted) {
                    const formattedAttachments = attachments.map((attachment: any) => ({
                        name: attachment.Key,
                        type: attachment.Type,
                        date: formatISODateTime(new Date(attachment.LastModified)),
                    }));
                    setFiles(formattedAttachments);
                }
            } catch (error) {
                console.error("Error fetching attachments:", error);
                if (isMounted) {
                    showNotificationRef.current('Failed to fetch attachments.', 'error');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [complaintId, formatISODateTime, refreshKey]);

    const handleRefresh = useCallback(() => {
        setRefreshKey(prevKey => prevKey + 1);
    }, []);

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} height={"auto"}>

                <Typography variant="h6" gutterBottom>
                    Attached files
                </Typography>
                <IconButton onClick={handleRefresh} disabled={loading}>
                    <RefreshIcon />
                </IconButton>
            </Box>
            <Divider sx={{ mb: 0 }} />
            <Grid
                sx={{
                    width: '100%',
                    flexGrow: 1
                }}
                item
                container
                justifyContent="center"
                alignItems="center"
                py={2}
                md={12}>

                <TableContainer>

                    <Table

                        sx={{
                        }}
                        aria-label="simple table">


                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell>File Type</TableCell>
                                {/* <TableCell>Assigned as</TableCell> */}
                                <TableCell>Date Uploaded</TableCell>
                                {/* <TableCell>URL</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {files.length > 0 ? (
                                        files.map((file, index) => (
                                            <TableRow key={index}>
                                                <TableCell key={index} component="th" scope="row">
                                                    <Typography variant="body2" fontWeight={"bold"} >{file.name}</Typography>

                                                </TableCell>
                                                <TableCell>{file.type}</TableCell>
                                                {/* <TableCell>
                                        <Chip label={file.category} />  
                                </TableCell> */}
                                                <TableCell>{file.date}</TableCell>
                                                {/* <TableCell>
                                    <IconButton href={file.url}><FileDownloadIcon color="primary" /></IconButton>
                                </TableCell> */}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                No attachments found
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            {/* <TableCell></TableCell>
                            <TableCell></TableCell> */}
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>


                </TableContainer>
            </Grid>
        </>
    );
};
