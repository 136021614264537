import { useState, useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import DetailsCard from "./detailscard";
import { TranscriptEntryTimings, TranscriptElementProps, TranscriptTextProps, TranscriptWordProps } from "../../../interfaces/interfaces";
import { Typography } from "@mui/material";

// Creates and updates each span element depending on audio time for highlighting purposes
const TranscriptSpan: React.FC<TranscriptWordProps> = ({ data, index, currentTime }) => {
  const [updatedData, setUpdatedData] = useState<any>();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const updateData = () => {
      let className = '';
      const updated = data.map((element: TranscriptEntryTimings) => {
        // To highlight needs to be less than currentPlayTime
        const isCurrentTimeInRange = parseFloat(element.start_time) <= currentTime;
        // And element must be within half second current time
        const isElementBeforeTime = parseFloat(element.start_time) <= (currentTime - 0.3);
        const isElementAheadTime = parseFloat(element.start_time) > (currentTime + 0.05);
        // prevEndTime = parseFloat(element.end_time)
        if (isCurrentTimeInRange && !isElementBeforeTime && !isElementAheadTime) {
          className = 'highlighted';
        }
        if (isElementAheadTime) {
          className = '';
        }

        return { ...element, className };
      });
      setHasData(true);
      setUpdatedData(updated);
    };
    updateData();
  }, [data, currentTime]);

  return (
    <>
      {hasData ? (
        updatedData.map((word: any | null, index: number) => (
          <span
            key={index}
            data-start={word.start_time} data-end={word.end_time}
            className={word.className}
          >{word.transcript} </span>
        ))
      ) : (
        <>Loading...</>
      )}
    </>
  )
}

// Creates the transcript paragraph of the current speaker
const TranscriptText: React.FC<TranscriptTextProps> = ({ data, index, currentTime }) => {
  const [updatedData, setUpdatedData] = useState<TranscriptEntryTimings[]>([]);
  //console.log("data", data)
  useEffect(() => {
    const updateData = () => {
      const updated = data.conversation_timings.map((element: TranscriptEntryTimings) => {
        return { ...element };
      });
      setUpdatedData(updated);
    };
    updateData();
  }, [data, currentTime]);
  return (
    <TranscriptSpan key={index} currentTime={currentTime} index={index} data={updatedData} />
  )
}

// Creates the Speaker Entry Element with sentiment and icons etc..
const TranscriptEntryElement: React.FC<TranscriptElementProps> = ({ data, index, currentTime }) => {
  const speakerClass = index % 2 === 0 ? "speaker1" : "speaker2";

  return (
    <DetailsCard transcriptType={true} key={index}>
      <div className={`transcript-part ${speakerClass}`}>
        <div className="transcript-speaker-icon">
          {index % 2 === 0 ? (
            <AccountBalanceIcon fontSize="small" />
          ) : (
            <PersonIcon fontSize="small" />
          )}
        </div>
        <div className="transcript-text">
          {data.map((part, partIndex) => (
            <div key={partIndex}>
              <TranscriptText key={partIndex} currentTime={currentTime} index={index} data={part} />
            </div>
          ))}
        </div>
      </div>
      <div className="transcript-part-adj">
        <div>
          <Typography variant="caption">
            Timestamp: {data[0].start_time}s - {data[0].end_time}s
          </Typography>
        </div>
      </div>
    </DetailsCard>
  );
};

export default TranscriptEntryElement;