import { Authenticator, Image, View, useTheme } from "@aws-amplify/ui-react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { ThemeProvider, Typography, Grid } from "@mui/material";


import "./assets/styles/base.css";
// import "./assets/styles/heading.css";
import "./assets/styles/typography.css";
import "./App.css";

import { defaultTheme } from "./core/theme";
import Home from "./pages/home/home";
import ComplaintDetails from "./pages/complaint-details/ComplaintDetails";
import ComplaintsTracker from "./pages/complaints-tracker/ComplaintsTracker";
import NewComplaint from "./pages/new-complaint/NewComplaint";
import ComplaintGenerator from "./pages/complaint-generator/ComplaintGenerator";
import PurgeComplaints from "./pages/purge-complaints/Purge";
import NotFound  from "./pages/error/NotFound";


import LogoImage from "./assets/images/PWCLogo.svg";

// import {AppRoutes} from "./routes";
import { AppRoutes } from './config/routesConfig';
import Layout from "./pages/layout";

const router = createBrowserRouter(createRoutesFromElements(
    <Route path={AppRoutes.home.path} element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={AppRoutes.tracker.path} element={<ComplaintsTracker />} />
        <Route path={AppRoutes.details.path} element={<ComplaintDetails />} />
        <Route path={AppRoutes.new.path} element={<NewComplaint />} />
        <Route path={AppRoutes.generator.path} element={<ComplaintGenerator />} />
        <Route path={AppRoutes.purge.path} element={<PurgeComplaints />} />
        <Route path="*" element={<NotFound />} />
    </Route>
));

export default function App() {
    const { tokens } = useTheme();
    const components = {
        Header() {
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Grid container alignItems={"center"} justifyContent={"center"} mt={10} mb={2}>
                        <Grid item pr={1} alignItems={"center"} alignContent={"center"} display={"flex"}>
                            <Image src={LogoImage} alt="PWC" width="48px" />
                        </Grid>
                        <Grid alignItems={"center"} alignContent={"center"} >
                            <Typography variant="h4" fontWeight={400}>Complaints Intelligence</Typography>
                        </Grid>
                    </Grid>
                </View>
            );
        },
        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Typography variant="caption">
                        &copy; 2024 PricewaterhouseCoopers UK. All Rights Reserved.
                    </Typography>
                </View>
            );
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Authenticator components={components} hideSignUp>
                <RouterProvider router={router} />
            </Authenticator>
        </ThemeProvider>
    );
}
