import React from 'react';
import { Card, CardContent, Typography, Box, Skeleton } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

// Generic MetricCard component
interface MetricCardProps {
    title: string;
    value: string | number;
    loading?: boolean;
    children?: React.ReactNode;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, loading = false, children }) => (
    <Card variant="outlined" sx={{ minHeight: "200px" }}>
        <CardContent>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            {loading ? (
        <Skeleton variant="rounded" width={100} height={100} />
      ) : (
            <Typography variant="h3" fontWeight={"light"} sx={{ fontSize: "5em" }}>{value}</Typography>
        )}
            {children}
        </CardContent>
    </Card>
);

// Total Complaints component
export const TotalComplaints: React.FC<{ total: number; loading: boolean }> = ({ total, loading }) => (
    <MetricCard title="Total Complaints" value={total} loading={loading} >
        <Typography variant="body2" color="text.secondary">
            (any status)
        </Typography>
    </MetricCard>
);

// Complaints by Status component
interface StatusData {
    id: string;
    value: number;
    label: string;
}

export const ComplaintsByStatus: React.FC<{ data: StatusData[]; loading: boolean }> = ({ data, loading }) => (
    <MetricCard title="Complaints by Status" value="" loading={loading}>
        <Box height={200}>
        {loading ? (
        <Skeleton variant="rectangular" width="100%" height={100} sx={{marginTop: "10px"}} />
      ) : (
            <PieChart
                colors={['#415385', '#5f5c95', '#7d64a1', '#9d6cab', '#bc74b2']}
                series={[
                    {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30 },
                    },
                ]}
                slotProps={{
                    legend: {
                        labelStyle: {
                            fontSize: "0.8em",
                        },
                    },
                }}
                height={200}
            />
        )}
        </Box>
    </MetricCard>
);

// Complaints by Category component
interface CategoryData {
    id: string;
    value: number;
    label: string;
}

export const ComplaintsByCategory: React.FC<{ data: CategoryData[]; loading: boolean }> = ({ data, loading }) => (
    <MetricCard title="By Category" value="" loading={loading}>
        <Box height={200}>
        {loading ? (
        <Skeleton variant="rectangular" width="100%" height={100} sx={{marginTop: "10px"}} />
      ) : (
            <PieChart
                colors={['#415385', '#5f5c95', '#7d64a1', '#9d6cab', '#bc74b2']}
                series={[
                    {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30 },
                    },
                ]}
                slotProps={{
                    legend: {
                        labelStyle: {
                            fontSize: "0.8em",
                        },
                    },
                }}
                height={200}
            />
        )}
        </Box>
    </MetricCard>
);

// Average Age component
export const AverageAge: React.FC<{ averageAge: number; loading: boolean }> = ({ averageAge, loading }) => (
    <MetricCard title="Average Age" value={averageAge.toFixed(1)} loading={loading}>
        <Typography variant="body2" color="text.secondary">
            (days since creation)
        </Typography>

    </MetricCard>
);

// Complaints Requiring Attention component
export const ComplaintsOld: React.FC<{ count: number; loading: boolean }> = ({ count, loading }) => (
    <MetricCard title="Older than 14 days" value={count} loading={loading}>
        <Typography variant="body2" color="text.secondary">
            (Older than 14 days)
        </Typography>
    </MetricCard>
);


// Complaints Requiring Attention component
export const ComplaintsRequiringAttention: React.FC<{ count: number; loading: boolean }> = ({ count, loading }) => (
    <MetricCard title="Requiring Attention" value={count} loading={loading}>
        <Typography variant="body2" color="text.secondary">
            (Requires review by Handler)
        </Typography>
    </MetricCard>
);