export const ComplaintDetailsTabs = [
  {
    label: "Case Details",
    value: 'caseDetails'
  },
  {
    label: "Attachments",
    value: 'attachments'
  },
  {
    label: "Risk Assessment",
    value: 'riskAssessment'
  },
  {
    label: "Findings",
    value: 'findings'
  },
  {
    label: "Response",
    value: 'response'
  },
];

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "new":
      return "default";
    case "processing":
      return "warning";
    case "requires handler review":
      return "error";
    case "ready for review":
      return "success";
    default:
      return "primary";
  }
};

export const getPSD2Color = (status: string) => {
  switch (status) {
    case "No":
      return "success";
    case "Yes":
      return "warning";
    default:
      return "default";
  }
};

export const getAgeColour = (age?: number) => {
  if (age === undefined || age === null) {
    return "default"
  } else {
    if (age <= 4) {
      return "default"
    } else if (age > 4 && age <= 8) {
      return "warning"
    } else if (age > 8) {
      return "error"
    }
  }
};
