import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Divider } from '@mui/material';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface ExpandableCardProps {
    title: string;
    children: React.ReactNode;
    minHeight?: string;
    showIcon?: boolean;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({ title, children, minHeight = '300px', showIcon = true }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card variant="outlined" style={{ padding: '8px', minHeight }}>
            <CardContent>
                <Box display="flex" alignItems="center">

                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        gutterBottom
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        {title}
                        {showIcon && (
                            <span style={{ marginLeft: '4px' }}>
                                <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ ml: 1 }} />
                            </span>
                        )}
                    </Typography>
                    
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Box
                    sx={{
                        height: expanded ? 'auto' : minHeight,
                        overflow: 'hidden',
                        position: 'relative',
                        '&::after': {
                            content: '""',
                            display: expanded ? 'none' : 'block',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '30px',
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
                        },
                    }}
                >
                    {children}
                </Box>
                <Box display="flex" justifyContent="center">
                    <IconButton onClick={handleExpandClick}>
                        {expanded ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                    </IconButton>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ExpandableCard;