import { useState } from "react";
import {
    Box,
    Typography,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Alert,
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { fetchAuthSession } from "aws-amplify/auth";
import { BASE_ENGINE_API_URL } from "../../../api";
import React from "react";
import Notification from "../../../components/Notification";
import useNotification from "../../../hooks/useNotification"; // Import the custom hook


async function uploadFile(complaintId: string, file: File, handleOpenDialog: () => void) {
    try {
        console.log("uploadFile called with file:", file);
        if (file.type !== 'audio/mpeg') {
            console.error("Invalid file type. Only .mp3 files are allowed.");
            handleOpenDialog();
            return null;
        }

        console.log("Fetching Auth Session");
        var cognitoTokens = (await fetchAuthSession()).tokens;
        let identityToken = cognitoTokens?.idToken?.toString();
        console.log("Identity Token: ", identityToken);

        console.log(complaintId);
        const requestBody = {
            type: "upload_audiorecording",
            complaint_id: complaintId
        };

        const presignResponse = await fetch(`${BASE_ENGINE_API_URL}/presign`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${identityToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        if (!presignResponse.ok) {
            throw new Error(`Failed to get presigned URL. Status: ${presignResponse.status}`);
        }

        let presignedUrl = await presignResponse.text();
        presignedUrl = presignedUrl.replace(/['"]+/g, ""); // Remove any quotes around the URL
        console.log("Cleaned Presigned URL:", presignedUrl);

        const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
                "Content-Type": file.type
            },
            body: file
        });

        if (!uploadResponse.ok) {
            const errorText = await uploadResponse.text();
            throw new Error(`Failed to upload file. Status: ${uploadResponse.status}, Response: ${errorText}`);
        }

        console.log("File uploaded successfully!");
        return "File uploaded successfully!" + uploadResponse.url;
    } catch (error) {
        console.error("Error uploading file:", error);
        return null;
    }
}

interface Props {
    complaintId: string;
}

const UploadAttachment: React.FC<Props> = ({ complaintId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const clearFile = () => {
        setSelectedFile(null);
    };
    const [fileName, setFileName] = useState<String | null>("");
    const [open, setOpen] = useState(false);
    const { notification, showNotification, closeNotification } = useNotification();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            setSelectedFile(file);
            setFileName(file.name);
        }
    };

    const handleUploadClick = async () => {
        if (!selectedFile) return;
        showNotification('Your file upload has started.', 'info');
        setTimeout(() => {}, 2000);

        const confirmation = await uploadFile(complaintId, selectedFile, handleOpenDialog);
        if (confirmation) {
            showNotification('Your file upload was successful.', 'success');
            setTimeout(() => {
                window.location.reload();
              }, 4000);
        } else {
            showNotification('Failed to upload file.', 'error');
        }
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <>
            {/* upload section */}
            <>
                {/* <Box>
                    <Typography variant="h6" gutterBottom>
                        Upload an attachment
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="body2" gutterBottom>
                        Please upload a file that contains details about the customer complaint. For
                        example, a call recording, email or scanned letter. You can upload MP3, PNG or
                        PDF files. The max file size is 10MB.
                    </Typography>
                    <Alert severity="warning"> Currently, the solution can only ingest call recordings (.mp3).  </Alert>
                </Box> */}
                <Box
                    border={"1px dashed rgba(0, 0, 0, 0.12)"}
                    marginTop={"16px"}
                    width={"100%"}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    py={2}
                >
                    {!selectedFile && (
                        <Button
                            style={{}}
                            component="label"
                            role={undefined}
                            variant="outlined"
                            tabIndex={-1}
                            size="large"
                            >
                            <FolderOpenIcon sx={{ mr: 1 }} />Select a file
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange} />
                        </Button>
                    )}
                    {selectedFile && fileName && (
                        <Alert icon={false} severity="info">
                            <b>Selected file:</b> {fileName}
                            <IconButton onClick={clearFile}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </Alert>
                    )}
                </Box>
                <Box mt={2} display="flex" justifyContent={"center"} alignItems={"center"}>
                    {selectedFile && fileName && (
                        <Button
                            size="medium"
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<FileUploadIcon />}
                            onClick={handleUploadClick}>
                            Upload
                        </Button>
                    )}
                </Box>
            </>


            {/* Notifications, alerts and dialog boxes  */}
            {/* invalid file type dialog alert */}
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography variant="h5">Invalid file type</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body2">
                            Only .mp3 files are allowed.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Reusable Notification component */}
            <Notification
                open={notification.open}
                message={notification.message}
                onClose={closeNotification}
                severity={notification.severity} />
        </>
    );
};
export default UploadAttachment;