import { useState } from "react";
import { Alert, AlertTitle, Box, TextField, Typography, Button } from "@mui/material";
import { CustomerDetailsContent } from "./CustomerDetailsContent";
import { isEmptyObject } from "../../../constants/utilities";
import { CustomerData } from "../../../interfaces";
import { CustomerComplaints } from "./CustomerComplaints";
import LoadingComponent from "../../../components/loading";

interface Props {
    fetchingCustomerDetails: boolean;
    data?: CustomerData;
    handleChange: Function;
}

export function CustomerSearch({
    fetchingCustomerDetails,
    data,
    handleChange
}: Props) {
    const [inputText, setInputText] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value;
        setInputText(value);
        setError(value.length < 6);
    };

    const triggerSearch = () => {
        if (inputText.length >= 6) {
            handleChange(inputText);
        } else {
            setError(true);
        }
    };

    const onSearchClick = () => {
        triggerSearch();
    };

    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            triggerSearch();
        }
    };


    return (
        <Box>
            <Box
                sx={{
                    mb: "32px",
                }}
            >
                <Box mt={1} width={"450px"} display={"flex"} alignItems={"center"} gap={2}>
                    <TextField
                        size="small"
                        style={{
                            width: "400px",
                        }}
                        id="filled-basic"
                        label="Enter a Customer ID"
                        variant="filled"
                        onChange={onTextChange}
                        onKeyPress={onKeyPress}
                        error={error}
                        helperText={error ? "Customer ID must be at least 6 characters long" : ""}
                    />
                    <Button size="large" variant="contained" onClick={onSearchClick} disabled={fetchingCustomerDetails || error}>
                        Search
                    </Button>
                </Box>
            </Box>

            <Box>
                {fetchingCustomerDetails && (
                    <div>
                        <Typography variant="body2" gutterBottom pb={2} pl={1}>Searching for customer...</Typography>
                        <LoadingComponent />
                    </div>
                )}
                {!fetchingCustomerDetails && data && (
                    <>
                        <CustomerDetailsContent data={data}></CustomerDetailsContent>
                        <br></br>
                        {(data?.open_complaints.length > 0) && (
                        <>
                        <Box mt={0} mb={3}>
                        <Alert variant="filled" severity="warning">
                        <AlertTitle>
                            We've found existing complaints for {data.customer_first_name} {data.customer_last_name}
                        </AlertTitle>
                        
                            Below is a list of open complaints associated with this customer. Please review and update as necessary before opening a new case.
                        </Alert>
                            
                        
                        </Box>
                        <CustomerComplaints data={data}></CustomerComplaints>
                        </>
                        )}
                        
                    </>
                )}

                {!fetchingCustomerDetails && inputText && isEmptyObject(data) && (
                    <Box
                        style={{
                            marginBottom: "32px",
                            marginTop: "32px",
                        }}
                    >
                        {/* <Typography color={"grey"}>
                            Please enter a valid customer ID and click search
                        </Typography> */}
                    </Box>
                )}
            </Box>
        </Box>
    );
}