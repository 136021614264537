import { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Chip,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert

} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TranscriptEntryElement from "./SpeakerTranscript";
import { fetchAudioFile } from "../../../api/cms-api";
import LoadingComponent from "../../../components/loading";
import BulletPointList from "../../../components/bulletpoints";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import HeadingWithIcon from "../../../components/HeadingWithIcon";
import ExpandableCard from "../../../components/ExpandableCard";
import { TranscriptEntry, TranscriptSorted } from "../../../interfaces/interfaces";

// Formats the transcript providing: Speaker Name, Text block, Start/End Times
// Sentiment/Confidence
function formatTranscriptArray(transcript: TranscriptEntry[]): any[] {
    let conversationArray: any[] = [];
    let prevIsPunctuation = false;
    let conversation = "";
    let currentSpeaker = "";
    let minTime = 0;
    let maxTime = 0;
    let confidenceDivision = 0;
    let confidenceAverage = 0;
    let confidenceTotal = 0;
    let conversationTimings: any[] = [];

    let sortedTranscript = {
        speaker_label: "",
        start_time: "",
        end_time: "",
        confidence: 0,
        transcript: "",
        conversation_timings: conversationTimings
    };

    let transcriptTiming = {
        speaker_label: "",
        start_time: "",
        end_time: "",
        transcript: ""
    };

    transcript.forEach((entry) => {
        const { speaker_label, alternatives } = entry;
        const text = alternatives.map((alt) => alt.content).join(" ");
        const confidence = alternatives.map((alt) => alt.confidence).join(" ");

        if (currentSpeaker !== speaker_label) {
            //console.log(conversation); Dont assign the first record with blank speaker
            if (speaker_label !== "" && conversation !== "") {
                // do toFixed here, as returns string, and parseFloat to get number back
                confidenceTotal = parseFloat((confidenceAverage / confidenceDivision).toFixed(2));
                sortedTranscript = {
                    speaker_label: currentSpeaker,
                    start_time: minTime.toFixed(4),
                    end_time: maxTime.toFixed(4),
                    confidence: confidenceTotal,
                    transcript: conversation,
                    conversation_timings: conversationTimings
                };
                // if not currentSpeaker push sortedTranscript{} to array
                conversationArray.push(sortedTranscript);
            }
            // reset values ready for next speaker loop
            minTime = 0;
            maxTime = 0;
            conversation = "";
            confidenceDivision = 0;
            confidenceAverage = 0;
            conversationTimings = [];

            // extract start and end times for new speaker conv
            if (minTime === 0 && maxTime === 0) {
                minTime = parseFloat(entry.start_time);
                maxTime = parseFloat(entry.end_time);
            }
            // Build up the average calculation
            confidenceDivision += 1;
            confidenceAverage += parseFloat(confidence);
            conversation += `${text}`;
            currentSpeaker = speaker_label;
            //Add transcript time event for audio highlighting
            transcriptTiming = {
                speaker_label: currentSpeaker,
                start_time: entry.end_time,
                end_time: entry.end_time,
                transcript: `${text}`
            };
            conversationTimings.push(transcriptTiming);
        } else {
            // Increase maxTime if required
            if (maxTime < parseFloat(entry.end_time)) {
                maxTime = parseFloat(entry.end_time);
            }
            // Build up the average calculation
            confidenceDivision += 1;
            confidenceAverage += parseFloat(confidence);
            // Build up the conversation
            if (entry.type === "punctuation") {
                prevIsPunctuation = true;
                conversation += `${text}`;
            } else {
                prevIsPunctuation = false;
                if (!prevIsPunctuation) {
                    conversation += ` ${text}`;
                } else {
                    conversation += `${text}`;
                }
            }
            //Add transcript time event for audio highlighting
            transcriptTiming = {
                speaker_label: currentSpeaker,
                start_time: entry.end_time,
                end_time: entry.end_time,
                transcript: `${text}`
            };
            conversationTimings.push(transcriptTiming);
        }
    });

    return conversationArray; // Returns array of TranscriptSorted obj's
}


const Transcript = ({ transcript, complaint }: any) => {
    const [audioFile,
        setAudioFile] = useState<string>();
    const [convo,
        setConvo] = useState<any>();
    const [loading,
        setLoading] = useState<boolean>(true);
    const [audioCurrentTime, setAudioCurrentTime] = useState(parseFloat("0.00"));
    const [prevAudioCurrentTime, setPrevAudioCurrentTime] = useState(parseFloat("0.00"));

    useEffect(() => {
        const fetchAndSetData = async () => {
            if (transcript && transcript.results && transcript.results.items) {
                const audioPath = await fetchAudioFile(complaint.complaint_id); // Ensure fetchAudioFile returns a promise
                setAudioFile(audioPath);
                const conversation = formatTranscriptArray(transcript.results.items);
                setConvo(conversation);
                setLoading(false);
            }
        };

        fetchAndSetData();
    }, [transcript, complaint]);

    const handleTimeUpdate = (event: any) => {
        setPrevAudioCurrentTime(audioCurrentTime);
        setAudioCurrentTime(event.target.currentTime);
    };

    return (
        <Grid container spacing={3} mt={0} justifyContent="space-between">
            {complaint.complexity_check.Decision ? (
                <>

                    {/* severity checks */}
                    <Grid item xs={12}>
                        <Card variant="outlined" style={{ padding: '0px' }}>
                            <CardContent>
                                <HeadingWithIcon
                                    icon={< AutoAwesomeIcon color="primary" fontSize="inherit" />}
                                    variant="h6"
                                    fontWeight="bold">
                                    Case Severity Checks:
                                </HeadingWithIcon>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={2} paddingBottom={1}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" fontWeight="bold">Financial:</Typography>
                                        {complaint.complaint_severity && complaint.complaint_severity.Financial
                                            ? <BulletPointList paragraph={complaint.complaint_severity.Financial} />
                                            : 'TBA'}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" fontWeight="bold">Reputational:</Typography>
                                        {complaint.complaint_severity && complaint.complaint_severity.Reputational
                                            ? <BulletPointList paragraph={complaint.complaint_severity.Reputational} />
                                            : 'TBA'}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant="subtitle2" fontWeight="bold">Customer Satisfaction:</Typography>
                                        {complaint.complaint_severity && complaint.complaint_severity.CustomerSatisfaction
                                            ? <BulletPointList paragraph={complaint.complaint_severity.CustomerSatisfaction} />
                                            : 'TBA'}
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>


                    {/* vulnerability, complexity and financial difficulty checks */}

                    {/* Vulnerability Check */}
                    <Grid item xs={12} md={4}>

                        <ExpandableCard title="Customer Vulnerability" showIcon>

                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Detection Result:
                                    </Typography>
                                    <Chip
                                        label={complaint.complaint_vulnerabilitycheck && complaint.complaint_vulnerabilitycheck.Decision
                                            ? complaint.complaint_vulnerabilitycheck.Decision
                                            : 'TBA'}
                                        size="small"
                                        color="default" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Analysis:
                                    </Typography>
                                    {complaint.complaint_vulnerabilitycheck && complaint.complaint_vulnerabilitycheck.Analysis
                                        ? <BulletPointList paragraph={complaint.complaint_vulnerabilitycheck.Analysis} />
                                        : 'TBA'}
                                </Grid>

                                <Accordion disableGutters elevation={0}>
                                    <AccordionSummary
                                        expandIcon={< ExpandCircleDownIcon />}
                                        aria-controls={`panel1-content`}
                                        id={`panel1-header`}>
                                        <Typography variant="subtitle2" fontWeight={'bold'}>References used:
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {complaint.complaint_vulnerabilitycheck && complaint.complaint_vulnerabilitycheck.References
                                            ? complaint.complaint_vulnerabilitycheck.References.map((item: { Reference: any; Reason: any; }, index: any) => (
                                                <Typography key={index} style={{ display: 'list-item' }} variant="body2">{item.Reference} - {item.Reason}</Typography>
                                            ))
                                            : 'No references available'}
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>
                        </ExpandableCard>




                    </Grid>

                    {/* Financial Difficulty Check */}
                    <Grid item xs={12} md={4}>

                        <ExpandableCard title="Financial Difficulty" showIcon>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Detection Result:
                                    </Typography>
                                    <Chip
                                        label={complaint.complaint_financialdifficultycheck && complaint.complaint_financialdifficultycheck.Decision
                                            ? complaint.complaint_financialdifficultycheck.Decision
                                            : 'TBA'}
                                        size="small"
                                        color="default" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Analysis:
                                    </Typography>
                                    {complaint.complaint_financialdifficultycheck && complaint.complaint_financialdifficultycheck.Analysis
                                        ? <BulletPointList paragraph={complaint.complaint_financialdifficultycheck.Analysis} />
                                        : 'TBA'}
                                </Grid>


                                <Accordion disableGutters elevation={0}>
                                    <AccordionSummary
                                        expandIcon={< ExpandCircleDownIcon />}
                                        aria-controls={`panel1-content`}
                                        id={`panel1-header`}>
                                        <Typography variant="subtitle2" fontWeight={'bold'}>References used:
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {complaint.complaint_financialdifficultycheck && complaint.complaint_financialdifficultycheck.References
                                            ? complaint.complaint_financialdifficultycheck.References.map((item: { Reference: any; Reason: any; }, index: any) => (
                                                <Typography key={index} style={{ display: 'list-item' }} variant="body2">{item.Reference} - {item.Reason}</Typography>
                                            ))
                                            : 'No references available'}
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>

                        </ExpandableCard>

                    </Grid>

                    {/* Complexity Check */}
                    <Grid item xs={12} md={4}>

                        <ExpandableCard title="Complexity" showIcon>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Detection Result:
                                    </Typography>
                                    <Chip
                                        label={complaint.complexity_check && complaint.complexity_check.Decision
                                            ? complaint.complexity_check.Decision
                                            : 'TBA'}
                                        size="small"
                                        color="default" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight={'bold'}>Analysis:
                                    </Typography>
                                    {complaint.complexity_check && complaint.complexity_check.Analysis
                                        ? <BulletPointList paragraph={complaint.complexity_check.Analysis} />
                                        : 'TBA'}
                                </Grid>
                            </Grid>

                        </ExpandableCard>

                    </Grid>


                    {/* <Grid container spacing={2} justifyContent="space-between" mb={2}> */}
                    <Grid item xs={12}>
                        <Card
                            variant="outlined"
                            style={{
                                padding: '8px',
                                minHeight: '400px'
                            }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Transcript
                                </Typography>
                                <Divider
                                    sx={{
                                        mb: 2
                                    }} />
                                {transcript === "N/A"
                                    ? (
                                        <Box>
                                            <Typography gutterBottom>
                                                There is no Transcript available at this time. Please check back again later.
                                            </Typography>
                                        </Box>
                                    )
                                    : (
                                        <Grid container
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                {!loading
                                                    ? (
                                                        <Grid container justifyContent={"space-between"} spacing={3}>
                                                            <Grid item xs={6}>
                                                                <Grid container spacing={2} direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2">
                                                                            Speaker Key:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <div className={`transcript-part speaker1`}>
                                                                            <AccountBalanceIcon fontSize="small" sx={{ mr: 1 }} />
                                                                            <Typography variant="body2">
                                                                                StartUp Bank
                                                                            </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <div className={`transcript-part speaker2`}>
                                                                            <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                                                                            <Typography variant="body2">
                                                                                Customer
                                                                            </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                            <Grid item xs={6}>

                                                                {!audioFile || audioFile === ""
                                                                    ? (
                                                                        <div className="transcript-audio">
                                                                            <p>No Audio file found!</p>
                                                                        </div>
                                                                    )
                                                                    : (
                                                                        <div className="transcript-audio">
                                                                            <audio src={audioFile} controls onTimeUpdate={handleTimeUpdate} />
                                                                        </div>
                                                                    )}
                                                            </Grid>

                                                            {/* transcripts */}
                                                            <Grid item xs={12}>
                                                                <Card variant="outlined">
                                                                    {convo
                                                                        ? (convo.map((convoPart: TranscriptSorted | any, index: any | null) => (<TranscriptEntryElement
                                                                            key={index}
                                                                            currentTime={prevAudioCurrentTime}
                                                                            index={index}
                                                                            data={[convoPart]} />)))
                                                                        : (
                                                                            <Box>
                                                                                <p>No Transcript found.</p>
                                                                            </Box>
                                                                        )}
                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                    : (
                                                        <>
                                                            <LoadingComponent />
                                                        </>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    )}
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography variant="body2" gutterBottom>
                            Results not yet available. Please check back later.
                        </Typography>
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default Transcript;
