import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Chip,
    Skeleton
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { fetchComplaintList } from '../../api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AgeChip } from '../../components';
import { getStatusColor } from '../complaint-details/constants';
import {
    TotalComplaints,
    ComplaintsByStatus,
    ComplaintsByCategory,
    AverageAge,
    ComplaintsRequiringAttention,
    ComplaintsOld
} from './components/MetricComponents';
import { AppRoutes } from '../../config/routesConfig';

interface Complaint {
    complaint_id: string;
    customer_details: { customer_name: string };
    customer_id: string;
    date_created: string;
    complaint_status: string;
    complaint_categorisation: any;
}

const Home = () => {
    const navigate = useNavigate();
    const [complaints, setComplaints] = useState<Complaint[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchComplaintsData();
    }, []);

    const fetchComplaintsData = async () => {
        setLoading(true);
        try {
            const response = await fetchComplaintList();
            setComplaints(response);
        } catch (error) {
            console.error('Error fetching complaints:', error);
        }
        setLoading(false);
    };

    // Utility functions for calculating metrics
    const getTotalComplaints = () => complaints.length;

    const getComplaintsByStatus = () => {
        const statusCounts: { [key: string]: number } = {};
        complaints.forEach(complaint => {
            statusCounts[complaint.complaint_status] = (statusCounts[complaint.complaint_status] || 0) + 1;
        });
        return Object.entries(statusCounts).map(([name, value]) => ({ id: name, value, label: name }));
    };

    const getComplaintsByCategory = () => {
        const categoryCounts: { [key: string]: number } = {};
        complaints.forEach(complaint => {
            const category = complaint.complaint_categorisation.Decision || 'N/A';
            categoryCounts[category] = (categoryCounts[category] || 0) + 1;
        });
        return Object.entries(categoryCounts).map(([name, value]) => ({ id: name, value, label: name }));
    };

    const getAverageAge = () => {
        const totalAge = complaints.reduce((sum, complaint) => {
            const createdDate = new Date(complaint.date_created);
            const age = (new Date().getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24);
            return sum + age;
        }, 0);
        return totalAge / complaints.length;
    };

    const getComplaintsOld = () => {
        return complaints.filter(complaint => {
            const createdDate = new Date(complaint.date_created);
            const age = (new Date().getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24);
            return age > 14;
        }).length;
    };

    const getComplaintsRequiringAttention = () => {
        return complaints.filter(complaint => {
            return complaint.complaint_status === 'Requires Handler Review';
        }).length;
    };

    // Mini complaints table for recent activity
    const columns: GridColDef[] = [
        { field: 'complaint_id', headerName: 'ID', flex: 1 },
        { field: 'customer_name', headerName: 'Customer', flex: 1, renderCell: (params) => params.row.customer_details?.customer_name },
        { field: 'date_created', headerName: 'Created', flex: 1, renderCell: (params) => <AgeChip dateCreated={params.row.date_created} /> },
        {
            field: 'complaint_status', headerName: 'Status', flex: 1, renderCell: (params) => (
                <Chip size="small" label={params.row.complaint_status ?? "N/A"} color={getStatusColor(params.row.complaint_status)} />
            )
        },
        {
            field: "action",
            headerName: "Actions",
            flex: 1.4,
            minWidth: 250,
            renderCell: (params) => (
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start" gap={2}>
                    <Button
                        onClick={() => navigate(`/complaints/tracker/${params.row.complaint_id}`)}
                        variant="contained"
                        size="small"
                        style={{
                            textTransform: "none",
                        }}>Open</Button>
                </Box>
            )
        }
    ];

    return (
        <Box maxWidth={"lg"} mx={"auto"}>
            <Typography variant="h1" gutterBottom>
                Dashboard
            </Typography>
            <Grid container spacing={3}>
                {/* Navigation Cards */}
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" gutterBottom>View All Complaints</Typography>
                            <Typography variant="body2" paragraph>Access the full list of complaints and manage them efficiently.</Typography>
                            <Button
                                component={RouterLink}
                                to={AppRoutes.tracker.path || ''}
                                variant="contained"
                                color="primary"
                               
                            >
                                Open Complaints Tracker
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Create New Complaint</Typography>
                            <Typography variant="body2" paragraph>Record a new customer complaint and start the resolution process.</Typography>
                            <Button
                                component={RouterLink}
                                to={AppRoutes.new.path || ''}
                                variant="outlined"
                                color="secondary"
                                
                            >
                                Create New Complaint
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Metric Components */}
                <Grid item xs={12} sm={6} md={3}>
                    <TotalComplaints total={getTotalComplaints()} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AverageAge averageAge={getAverageAge()} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ComplaintsOld count={getComplaintsOld()} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ComplaintsRequiringAttention count={getComplaintsRequiringAttention()} loading={loading} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComplaintsByStatus data={getComplaintsByStatus()} loading={loading} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ComplaintsByCategory data={getComplaintsByCategory()} loading={loading} />
                </Grid>

                {/* Recent Activity */}
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Recent Activity</Typography>
                            {loading ? (
                <Skeleton variant="rounded" height={200} />
              ) : (
                            <DataGrid
                                loading={loading}
                                disableRowSelectionOnClick
                                getRowId={(row) => row.complaint_id}
                                rows={complaints.slice(0, 5)}
                                columns={columns}
                                initialState={{
                                    sorting: { sortModel: [{ field: 'date_created', sort: 'desc' }] },
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 10
                                        }
                                    }
                                }}
                                pageSizeOptions={[5, 10]}

                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: {
                                            disableToolbarButton: true
                                        },
                                        csvOptions: {
                                            disableToolbarButton: true
                                        }
                                    },
                                    columnHeaders: {
                                        style: {
                                            outline: 'none',
                                        },
                                    },
                                    cell: {
                                        style: {
                                            outline: 'none',
                                        },
                                    },

                                }}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                sx={{
                                    boxShadow: 0,
                                    border: 0
                                }} />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Home;