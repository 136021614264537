import React from 'react';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  StepIconProps
} from "@mui/material";
import { CheckCircle, Pause as PauseIcon, Pending as StepIcon, AccountCircle } from '@mui/icons-material';
import { styled } from '@mui/system';

const steps = [
  "Attach file",
  "Processing",
  "Risk Assessment",
  "Findings",
  "Response Generation",
  "Ready for Review"
];

const getStatusIndex = (status: string): number => {
  const statusMap: { [key: string]: number } = {
    'new': 0,
    'processing': 1,
    'analysis': 2,
    'complexity check': 2,
    'requires handler review': 2, // 'complexity check' is the same as 'needs attention'
    'decisioning': 3,
    'redress': 3,
    'frl generation': 4,
    'ready for review': 5,
  };

  return statusMap[status.toLowerCase()] ?? 0;
};

interface CustomStepIconProps extends StepIconProps {
  isError: boolean;
  warning?: boolean;
  isUserIcon?: boolean;
}

const CustomStepIconRoot = styled('div')<{
  ownerState: {
    active?: boolean;
    completed?: boolean;
    warning?: boolean;
    error?: boolean;
    user?: boolean;
  }
}>(({ theme, ownerState }) => ({
  color: ownerState.completed ? theme.palette.success.main
    : ownerState.warning ? theme.palette.warning.main
    : ownerState.error ? theme.palette.error.main
    : theme.palette.action.disabled,
  '& .MuiStepIcon-active': {
    color: ownerState.error ? theme.palette.error.main
      : ownerState.user ? theme.palette.primary.main
      : theme.palette.primary.main,
  },
  '& .MuiStepIcon-root': {
    color: ownerState.active || ownerState.completed || ownerState.error ? undefined : theme.palette.action.disabled,
  }
}));

const CustomStepIcon: React.FC<CustomStepIconProps> = (props) => {
  const { active, completed, className, isError, warning, isUserIcon } = props;

  return (
    <CustomStepIconRoot
      sx={{ padding: "0", height: "1.5em" }}
      ownerState={{ completed, active, warning, error: isError, user: isUserIcon }}
      className={className}>
      {completed && !isError && !isUserIcon
        ? <CheckCircle />
        : isError
        ? <PauseIcon />
        : isUserIcon
        ? <AccountCircle className={active ? 'MuiStepIcon-active' : ''} />
        : <StepIcon className={active ? 'MuiStepIcon-active' : ''} />}
    </CustomStepIconRoot>
  );
};

interface ComplaintStepperProps {
  complaint_status: string;
}

export const ComplaintStepper: React.FC<ComplaintStepperProps> = ({ complaint_status }) => {
  const activeStep = getStatusIndex(complaint_status);

  const isStepFailed = (step: number) => {
    return complaint_status.toLowerCase() === 'requires handler review' && step === getStatusIndex('complexity check');
  };

  const isUserStep = (step: number) => {
    return step === getStatusIndex('ready for review');
  };

  return (
    <Box px={4} sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

          if (complaint_status.toLowerCase() === 'new' && index === 0) {
            labelProps.optional = (
              <Typography variant="caption" color="warning">
                Please attach a file
              </Typography>
            );
          }

          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Requires Handler Review
              </Typography>
            );
            labelProps.error = true;
          }

          return (
            <Step key={index} completed={index < activeStep && !isStepFailed(index)}>
              <StepLabel StepIconComponent={(props) => <CustomStepIcon {...props} isError={isStepFailed(index)} warning={complaint_status.toLowerCase() === 'new' && index === 0} isUserIcon={isUserStep(index)} />} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};