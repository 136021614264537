// AttachmentsTab.tsx
import {
    Card,
    Grid,
    Typography,
    Box,
    Divider,
    Alert
} from "@mui/material";
import React from "react";
import { AttachedFiles } from "./AttachedFilesTable";
import UploadAttachment from "./FileUpload";

interface Props {
    complaintId: string;
}

const AttachmentsTab: React.FC<Props> = ({ complaintId }) => {


    return (
        <Grid container spacing={3} mt={0}>
            {/* upload section */}
            <Grid item md={4}>
                <Card
                    variant="outlined"
                    style={{
                        padding: '24px'
                    }}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Upload an attachment
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="body2" gutterBottom>
                            Upload files that contains details about the customer complaint. For
                            example, a call recording, email or scanned letter. These files will be used by the AI model to investigate the complaint.
                        </Typography>
                        <Alert severity="warning"> Currently, the solution can only ingest call recordings (.mp3).  </Alert>
                    </Box>
                    <UploadAttachment complaintId={complaintId} />
                </Card>
            </Grid>

            {/* attachments table section */}
            <Grid item md={8}>
                <Card
                    variant="outlined"
                    style={{
                        padding: '24px',
                    }}>
                    <AttachedFiles complaintId={complaintId.toString()} />
                </Card>
            </Grid>


        </Grid>
    );
};
export default AttachmentsTab;