export function formatUTCToGMT(inputDateStr: string): string {
  // Parse the input string into a Date object
  const date = new Date(inputDateStr.replace(" ", "T") + "Z");

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "GMT",
    weekday: "long", // "long" | "short" | "narrow" | undefined
    year: "numeric", // "numeric" | "2-digit" | undefined
    month: "long", // "numeric" | "2-digit" | "narrow" | "short" | "long" | undefined
    day: "numeric", // "numeric" | "2-digit" | undefined
    hour: "2-digit", // "numeric" | "2-digit" | undefined
    minute: "2-digit", // "numeric" | "2-digit" | undefined
    second: "2-digit", // "numeric" | "2-digit" | undefined
    hour12: false, // boolean | undefined
  };

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(date);

  // Return the formatted date
  return formattedDate;
}

export function formatDateOfBirth(dobStr: string): string {
  if (!dobStr) {
    return "";
  }
  // Split the input date string into year, month, and day components
  const [year, month, day] = dobStr?.split("/");

  // Format the date as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

// check if an object is empty
export const isEmptyObject = (obj?: object) => {
  return !obj || Object.keys(obj).length === 0;
};
