import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import BulletPointList from "../../../components/bulletpoints";

interface SummaryDialogProps {
    onClose: (value: string) => void;
    selectedValue: string;
    open: boolean;
}

export function SummaryDialog(props: SummaryDialogProps) {
    const { onClose, selectedValue, open } = props;
    const [summaryText, setSummaryText] = useState('');

    const handleClose = () => {
        onClose(selectedValue);
    };

    useEffect(() => {
        if (open) {
            try {
                const parsedValue = JSON.parse(selectedValue);
                setSummaryText(parsedValue.Summary);
            } catch (error) {
                console.error('Failed to parse JSON:', error);
                setSummaryText('Invalid summary format');
            }
        }
    }, [open, selectedValue]);

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                
                    Complaint Summary

            </DialogTitle>
            <DialogContent dividers>
                <BulletPointList paragraph={summaryText} listStyle="bullet" />
            </DialogContent>
        </Dialog>
    );
}