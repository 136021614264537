import {
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

interface Props {
  loading?: boolean;
  onNotesSubmit: (notes: string) => void;
  buttonLabel: string;
}

export function SubmitNotes({ loading, onNotesSubmit, buttonLabel }: Props) {
  const [notes, setNotes] = useState("");
  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          mb: "32px",
        }}
      >
        <Box
          sx={{
            my: "24px",
          }}
        >
          <TextField
            required
            fullWidth
            id="outlined-multiline-flexible"
            multiline
            rows={7}
            label="Details"
            onChange={handleNotesChange}
            value={notes}
            variant="filled"
          />
        </Box>
        <Button
          onClick={() => onNotesSubmit(notes)}
          
          variant="contained"
          disabled={!notes && loading}
        >
          {loading && (
            <CircularProgress
              style={{ color: "white", marginRight: 8 }}
              size={16}
            />
          )}
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
}