import { useState } from "react";

interface NotificationState {
  open: boolean;
  message: string;
  severity: "info" | "success" | "warning" | "error";
  autoHide?: boolean; // Optional property to control auto-hide behavior
}

const useNotification = () => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: "",
    severity: "info",
    autoHide: true // Default value for autoHide
  });

  const showNotification = (
    message: string, 
    severity: "info" | "success" | "warning" | "error",
    autoHide: boolean = true // Default value for autoHide
  ) => {
    setNotification({ open: true, message, severity, autoHide });
  };

  const closeNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return { notification, showNotification, closeNotification };
};

export default useNotification;