import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Chip,
    Divider,
    Tooltip,
    Alert,
    Popover,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    IconButton,
} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Visibility } from "@mui/icons-material";
import { AgeChip } from "../../../components";
import { getStatusColor } from "../constants";
import HeadingWithIcon from "../../../components/HeadingWithIcon";
// import { useTheme } from '@mui/material/styles';

// import { CustomerDialog } from "../components/CustomerDialog";

import UploadAttachment from "./FileUpload";
// import { AttachedFiles } from "./AttachedFilesTable";

interface Props {
    complaint: any;
}

export const CaseDetails = ({ complaint }: Props) => {

    // const theme = useTheme();

    // const [expanded, setExpanded] = useState(false);
    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };

    // const [open, setOpen] = useState(false);
    // const [selectedCustomer, setSelectedCustomer] = useState('');

    // const handleOpenDialog = (customer: string) => {
    //     setSelectedCustomer(customer);
    //     setOpen(true);
    // };

    // const handleCloseDialog = () => {
    //     setOpen(false);
    // };


    // ----- mouse over popover

    // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handlePopoverClose = () => {
    //     setAnchorEl(null);
    // };

    // const open = Boolean(anchorEl);

    // ----- on click popover

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Grid container spacing={3} mt={0} justifyContent="space-between">
            <Grid item xs={12} md={6}>
                <Card variant="outlined" style={{ padding: '0px' }}>
                    <CardContent style={{ minHeight: "350px" }}>
                        <Typography variant="h6" gutterBottom>
                            Case Details
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} paddingBottom={1}>
                            <Grid item xs={6} sm={4} md={4}>
                                <Typography variant="subtitle2" fontWeight="bold">Customer ID:</Typography>

                                <Chip
                                    component={"button"}
                                    label={complaint.customer_id}
                                    icon={<Visibility color="primary" fontSize="inherit" />}
                                    size="small"
                                    color="default"
                                    // aria-owns={open ? 'mouse-over-popover' : undefined}
                                    // aria-haspopup="true"
                                    // onMouseEnter={handlePopoverOpen}
                                    // onMouseLeave={handlePopoverClose}
                                    onClick={handleClick}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                />

                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <Typography variant="subtitle2" fontWeight="bold">Name:</Typography>
                                <Typography variant="body2">
                                    {complaint.customer_details.customer_salutation + " " + complaint.customer_details.customer_first_name + " " + complaint.customer_details.customer_last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <Typography variant="subtitle2" fontWeight="bold">Created:</Typography>
                                <AgeChip dateCreated={complaint?.date_created} />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <Typography variant="subtitle2" fontWeight="bold">Processing stage:</Typography>
                                <Chip label={complaint.complaint_status} size="small" color={getStatusColor(complaint.complaint_status)} />
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                                    Category :
                                </HeadingWithIcon>
                                <Tooltip title={complaint?.complaint_categorisation?.Analysis?.Category || "Pending"}>
                                    <Chip label={complaint.complaint_categorisation.Decision || "Pending"} size="small" color="default" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4}>
                                <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                                    PSD2 :
                                </HeadingWithIcon>
                                <Tooltip title={complaint?.complaint_categorisation?.Analysis?.PSD2 || "Pending"}>
                                    <Chip label={complaint.complaint_categorisation.PSD2 || "Pending"} size="small" color="default" />
                                </Tooltip>
                            </Grid>


                            <Grid item xs={6} sm={4} md={4}>
                                <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                                    Root Cause:
                                </HeadingWithIcon>
                                <Tooltip title={complaint?.complaint_categorisation?.RootCause || "Pending"}>
                                    <>
                                        {complaint.complaint_categorisation.RootCause
                                            ? (complaint.complaint_categorisation.RootCause.map((rootcause: string, index: number) => (
                                                <Chip key={index} label={rootcause} size="small" />
                                            )))
                                            : (<Chip label={"Pending"} size="small" />)}
                                    </>
                                </Tooltip>

                            </Grid>


                        </Grid>
                        <Divider sx={{ mb: 2, mt: 3 }} />

                        <HeadingWithIcon
                            icon={< AutoAwesomeIcon color="primary" fontSize="inherit" />}
                            variant="subtitle1"
                            fontWeight="bold">
                            Recommended Next Steps
                        </HeadingWithIcon>
                        {/* <Divider sx={{ mb: 2 }} /> */}
                        {complaint.complaint_status === 'Ready for Review' ? (
                            <Alert severity="success" variant='filled'>
                                <Typography variant="body2">
                                    <strong>Ready for review</strong>
                                    <br />
                                    This case is ready for review. Please review the findings and final response letter and take necessary actions.
                                </Typography>
                            </Alert>
                        ) : complaint.complaint_status === 'Requires Handler Review' ? (
                            <Alert severity="warning" variant='filled'>
                                <Typography variant="body2">
                                    <strong>Needs attention</strong>
                                    <br />
                                    This case requires further review and action by a Handler. Please review the Risk Assessment tab for more details and further action.
                                </Typography>
                            </Alert>
                        ) : (
                            <Alert severity="info" variant='outlined'>
                                <Typography variant="body2">
                                    <strong>No recommended next step available yet</strong>
                                    <br />
                                    This case currently does not have a recommended next step. Please check back later or consult a supervisor.
                                </Typography>
                            </Alert>
                        )}

                        <Divider sx={{ mb: 2, mt: 3 }} />
                        <HeadingWithIcon
                            icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
                            variant="subtitle1"
                            fontWeight="bold"
                        >
                            Case Summary
                        </HeadingWithIcon>
                        {/* <BulletPointList
                                paragraph={ complaint.complaint_summary.Summary || "No summary available yet" }
                                listStyle="bullet"
                            /> */}
                        <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                            {complaint.complaint_summary.Summary || "No summary available yet"}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                {complaint.complaint_status === 'New' ? (
                    <div className="pulse-container pulse">
                        <Card
                            variant="outlined"
                            style={{
                                minHeight: '150px',
                                marginBottom: '16px'
                            }}>
                            <CardContent>
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        Upload an attachment
                                    </Typography>
                                    <Divider sx={{ mb: 2 }} />
                                    <Alert severity="warning">Complaints Intelligence requires at least one (1) attachment to begin processing and investigation. </Alert>

                                </Box>

                                <UploadAttachment complaintId={complaint.complaint_id} />

                            </CardContent>
                        </Card>
                    </div>
                ) : (
                    null
                    // <Card
                    //     variant="outlined"
                    //     style={{
                    //         minHeight: '150px',
                    //         marginBottom: '16px'
                    //     }}>
                    //     <CardContent>
                    //         <AttachedFiles complaintId={complaint.complaint_id} />
                    //     </CardContent>
                    // </Card>
                )}
                <Card
                    variant="outlined"
                    style={{
                        minHeight: '150px',

                    }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Case Notes
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        {complaint.notes.length > 0 ? (
                            complaint.notes.map((element: any, index: any) => (
                                <Grid key={index} item xs={12}>
                                    <Typography variant="body2">
                                        <strong>Note {index + 1}</strong>: {element}
                                    </Typography>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="body2">No notes available yet</Typography>
                        )}
                    </CardContent>
                </Card>

            </Grid>
            <Grid item xs={12} md={4}>

            </Grid>
            {/* <CustomerDialog open={open} selectedValue={JSON.stringify(selectedCustomer)} onClose={handleCloseDialog} /> */}

            <Popover
                id="mouse-over-popover"

                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={handleClose}

            >
                <Box style={{ padding: '8px' }}>

                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">Name</TableCell>
                                    <TableCell>{`${complaint.customer_details.customer_salutation} ${complaint.customer_details.customer_first_name} ${complaint.customer_details.customer_last_name}`}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => { 
                                            navigator.clipboard.writeText(
                                                `${complaint.customer_details.customer_salutation} ${complaint.customer_details.customer_first_name} ${complaint.customer_details.customer_last_name}`
                                                ) }} >
                                        <ContentCopyIcon fontSize={"small"} />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Address</TableCell>
                                    <TableCell>
                                        {complaint.customer_details.customer_address_street}<br />
                                        {complaint.customer_details.customer_address_town}<br />
                                        {complaint.customer_details.customer_postcode}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => { 
                                            navigator.clipboard.writeText(
                                                `${complaint.customer_details.customer_address_street}
                                                ${complaint.customer_details.customer_address_town}
                                                ${complaint.customer_details.customer_postcode}`
                                                ) }} >
                                        <ContentCopyIcon fontSize={"small"} />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Phone</TableCell>
                                    <TableCell>{complaint.customer_details.customer_phone}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => { 
                                            navigator.clipboard.writeText(
                                                `${complaint.customer_details.customer_phone}`
                                                ) }} >
                                        <ContentCopyIcon fontSize={"small"} />
                                    </IconButton>
                                    </TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Email</TableCell>
                                    <TableCell>{complaint.customer_details.customer_email}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => { 
                                            navigator.clipboard.writeText(
                                                `${complaint.customer_details.customer_email}`
                                                ) }} >
                                        <ContentCopyIcon fontSize={"small"} />
                                    </IconButton>
                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">Date of Birth</TableCell>
                                    <TableCell>{complaint?.customer_details.customer_dob}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => { 
                                            navigator.clipboard.writeText(
                                                `${complaint.customer_details.customer_dob}`
                                                ) }} >
                                        <ContentCopyIcon fontSize={"small"} />
                                    </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Box>
            </Popover>

        </Grid>

    );
};