import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Grow
} from "@mui/material";
import { sidebarConfig, IconName, SidebarItem } from "../config/sidebarConfig";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Home, PendingActions, Segment, Settings, TableChart, AddCircle, AddIcCall, DeleteSweep } from "@mui/icons-material";
import { Link as RouterLink } from 'react-router-dom';

const expandedWidth = 250;
const collapsedWidth = 64; // Adjust as needed

const iconMap: Record<IconName, React.ElementType> = {
  Home,
  PendingActions,
  Segment,
  Settings,
  TableChart,
  AddCircle,
  AddIcCall,
  DeleteSweep
};


const useDelayedState = (initialState: boolean, delay: number) => {

  const [state, setState] = useState(initialState);
  const [delayedState, setDelayedState] = useState(initialState);

  useEffect(() => {
    const timer = setTimeout(() => setDelayedState(state), delay);
    return () => clearTimeout(timer);
  }, [state, delay]);

  return [state, setState, delayedState] as const;
};

export const AppSideBar = () => {
  const [expanded, setExpanded, delayedExpanded] = useDelayedState(false, 200);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderIcon = (iconName: IconName) => {
    const IconComponent = iconMap[iconName];
    return <IconComponent sx={{ color: "#252525" }} />;
  };

  const renderSidebarItem = (item: SidebarItem, index: number) => (
    <ListItemButton
      key={index}
      component={RouterLink}
      to={item.link || '/'}
      sx={{
        minHeight: 48,
        justifyContent: 'center',
        px: 2.5,
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: expanded ? 1 : 'auto',
          justifyContent: 'center',
        }}
      >
        {renderIcon(item.icon)}
      </ListItemIcon>
      {expanded && (
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            style: {
              fontSize: "0.9em",
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}
        />
      )}
    </ListItemButton>
  );

  return (
    <Box
      sx={{
        width: expanded ? expandedWidth : collapsedWidth,
        transition: 'all 0.3s ease',
        bgcolor: "#F3F3F3",
        height: '100%',
        overflow: "hidden",
        marginTop: "96px",
        marginRight: "24px",
        marginLeft: "24px",
        borderRadius: "8px",
        paddingBottom: "16px",
      }}
    >
      <Box sx={{
        height: 64,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      }}>
        <Grow in={delayedExpanded}>
          <Box sx={{ opacity: expanded ? 1 : 0, flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <Typography variant="body2" sx={{ letterSpacing: 0 }}>
              Welcome, User!
            </Typography>
          </Box>
        </Grow>
        <IconButton onClick={toggleExpanded} size="small">
          {expanded ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Box>
      <List component="nav" sx={{ padding: 0 }}>
        {sidebarConfig.map(renderSidebarItem)}
      </List>
    </Box>
  );
};