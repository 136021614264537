import React, { useState } from 'react';
import {
    Grid,
    Container,
    Typography,
    Button,
    Divider,
    Card,
    Box,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";
import { purgeComplaints } from '../../api';

const PurgeComplaints: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { notification, showNotification, closeNotification } = useNotification();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleRequestFile = async () => {
        setLoading(true);
        showNotification('Purging complaints, please do not leave this page.', 'info');
        setError(null);

        try {
            await purgeComplaints();
            showNotification('Complaints purged successfully!', 'success');
        } catch (err) {
            setError('Failed to purge complaints');
            showNotification('Failed to purge complaints', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmPurge = () => {
        handleRequestFile();
        handleCloseDialog();
    };

    return (
        <Container maxWidth={"md"} >
            <Typography variant="h1" marginBottom="24px" gutterBottom>
                Purge current complaints
            </Typography>

            <Card variant="outlined">
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <Box p={3}>
                            <Typography variant="h4" gutterBottom>
                                Danger zone
                            </Typography>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item sm={12} py={3}>
                        <Box px={3}>
                            <Alert severity="error" variant='filled'>
                                <Typography variant="body2" gutterBottom>
                                    This will purge all complaints cases from the database. This includes all the data, audio recordings, generated response letters, and case notes.
                                    This will not affect any other data in the system.
                                </Typography>
                            </Alert>

                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <Button
                                    variant="outlined"
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={handleOpenDialog}
                                    style={{ marginTop: '16px' }}
                                    disabled={loading}
                                >
                                    Purge Complaints
                                </Button>
                            </Box>



                        </Box>
                    </Grid>
                </Grid>
                {loading && <LinearProgress variant='indeterminate' style={{ marginTop: '16px' }} />}
                {error && <Typography color="error">{error}</Typography>}
            </Card>


            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
                
            >
                <DialogTitle id="confirm-dialog-title">Confirm Purge</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        Are you sure you want to purge all complaints? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    
                sx={{
                    padding: '1.5em',
                }}>
                    <Button onClick={handleCloseDialog} color="primary" variant='contained'>
                        No, cancel
                    </Button>
                    <Button onClick={handleConfirmPurge} color="error" variant='outlined' autoFocus>
                        Yes, I want to purge all complaints
                    </Button>
                </DialogActions>
            </Dialog>

            <Notification
                open={notification.open}
                message={notification.message}
                onClose={closeNotification}
                severity={notification.severity}
            />
        </Container>
    );
};


export default PurgeComplaints;