import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Button, IconButton, Tooltip } from "@mui/material";
import { Refresh, ChevronLeft} from '@mui/icons-material';
import Transcript from "./components/TabRiskAssessment";
import { fetchSingleComplaint } from "../../api";
import { ComplaintDetailsTabs } from "./constants";
import { CaseDetails, CustomTabPanel, Findings, Response } from "./components";
import AttachmentsTab from "./components/TabAttachments";
import LoadingComponent from "../../components/loading";
import CaseSpeedDial from "./components/CaseSpeedDial";
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";
import { ComplaintStepper } from "./components/Stepper";

const ComplaintDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [complaint, setComplaint] = useState<any | null>(null);
  const [transcript, setTranscript] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>("");
  const [value, setValue] = useState<any>(0);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const { notification, showNotification, closeNotification } = useNotification();
  const showNotificationRef = useRef(showNotification);

  useEffect(() => {
    showNotificationRef.current = showNotification;
  }, [showNotification]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (!id) {
        return;
      }
      const response = await fetchSingleComplaint(id);
      if (response.complaint_raw !== "N/A") {
        const transcriptRaw = JSON.parse(response.complaint_raw);
        setTranscript(transcriptRaw);
      } else {
        setTranscript(response.complaint_raw);
      }
      setComplaint(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error.message);
        showNotificationRef.current(error.message, "error");
      } else {
        setError('An error occurred while fetching the data. Please try again.');
        showNotificationRef.current('An error occurred while fetching the data. Please try again.', "error");
      }
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshKey]);

  const handleRefresh = useCallback(() => {
    setRefreshKey(prev => prev + 1);
  }, []);

  if (loading) return <LoadingComponent />;
  if (error) return <div>{error}</div>;

// hardcoded status for testing
      //  const complaintStatus = 'processing';

  return (
    <Box maxWidth={"lg"} alignItems={"center"} mx={'auto'}>
      <Box display="flex" alignItems="center" gap={1} mb={2} justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Tooltip title="Back to complaints tracker" placement="bottom">
        <IconButton color="primary" size="small" href="/complaints/tracker">
          <ChevronLeft />
        </IconButton>
        </Tooltip>
          <Typography variant="h3">
            Case ID: {id}
          </Typography>

        </Box>
        <Button 
          size="small"
          variant="outlined" 
          startIcon={<Refresh />} 
          onClick={handleRefresh}
        >
          Refresh
        </Button>
      </Box>
      <Box py={2} mb={2}>
      <ComplaintStepper complaint_status={complaint.complaint_status} />
      {/* <ComplaintStepper complaint_status={complaintStatus} /> */}
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#F3F3F3", borderRadius: "8px"}}>
        <Tabs value={value} onChange={handleChange}>
          {ComplaintDetailsTabs.map((tab: any, index: number) => (
            <Tab sx={{textTransform: 'none'}} key={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {complaint ? (
        <Box>
          <CustomTabPanel value={value} index={0}>
            <CaseDetails complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AttachmentsTab complaintId={complaint.complaint_id} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Transcript transcript={transcript} complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Findings complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Response complaint={complaint} complaintId={complaint.complaint_id} />
          </CustomTabPanel>
        </Box>
      ) : (
        <>
        <p>No complaint found with ID: {id}</p>
        </>
        
      )}
      <CaseSpeedDial complaint={complaint} showNotification={showNotification} onAddNotesSuccess={handleRefresh} />
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={closeNotification}
      />
    </Box>
  );
};

export default ComplaintDetails;