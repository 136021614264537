import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    Button,
    Box,
} from '@mui/material';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth={"md"} >
            <Typography variant="h1" marginBottom="24px" gutterBottom>
                Page not found
            </Typography>
            <Box>
                <Typography variant="body1" gutterBottom>
                    This also known as a 404 error! Check the URL and try again.
                </Typography>
            </Box>
            <Box>
                <Box display={"flex"} mt={4}>
                    <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                        Back to home
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default NotFound;