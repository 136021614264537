import React, { useState } from 'react';
import { Typography, SpeedDial, SpeedDialAction, SpeedDialIcon, Box, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { SubmitNotes } from '../../new-complaint/components/SubmitNotes';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { addNotesToComplaint, requestReprocess} from '../../../api'; 

interface CaseSpeedDialProps {
  complaint: any;
  showNotification: (message: string, severity: "info" | "success" | "warning" | "error") => void;
  onAddNotesSuccess: () => void
}


const CaseSpeedDial: React.FC<CaseSpeedDialProps> = ({ complaint, showNotification, onAddNotesSuccess }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleNotesSubmit = async (notes: string) => {
    setLoading(true);

    try {
      const updatedComplaint = {
        complaint_id: complaint?.complaint_id,
        notes_update: true,
        content: notes
      };
      showNotification('Submitting notes...', 'info');

      await addNotesToComplaint(updatedComplaint);

      onAddNotesSuccess();
      handleCloseDialog();
    } catch (error) {
      console.error('Error submitting notes:', error);
      showNotification('Failed to submit notes.', 'error');
    } finally {
      setLoading(false);
      showNotification('Notes submitted!', 'success');
    }
  };

  const handleReprocess = async () => {
    setLoading(true);
    showNotification('Requesting case re-process', 'info');
    try {
      await requestReprocess(complaint?.complaint_id);
      showNotification('Request to re-process was successful, refreshing data in a moment...', 'success');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (error) {
      console.error('Error requesting re-process:', error);
      showNotification('Failed to request re-process.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <SpeedDial
      ariaLabel="Speed dial"
      sx={{ position: 'fixed', bottom: 32, right: 32 }}
      icon={<SpeedDialIcon />}
    >
      <SpeedDialAction
        icon={<NoteAddIcon />}
        tooltipTitle="Add a Case Note"
        onClick={handleOpenDialog}
        tooltipOpen
        classes={{ staticTooltipLabel: 'speed-dial-tooltip' }}
      />
      <SpeedDialAction
        icon={<AutoModeIcon />}
        tooltipTitle="Re-process case"
        tooltipOpen
        classes={{ staticTooltipLabel: 'speed-dial-tooltip' }}
        onClick={handleReprocess}
      />
    </SpeedDial>

{/* dialog for adding notes */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
        <Typography variant="h3" gutterBottom>
          Add a Case Note
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please provide as much detail as possible as this will help both the
          AI model as well as the Complaints Handling team.
        </Typography>
          <SubmitNotes loading={loading} onNotesSubmit={handleNotesSubmit} buttonLabel="Update complaint" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" size='small'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CaseSpeedDial;