import { DetailsCardProps } from "../../../interfaces/interfaces";

// Creates the Details card and adjusts class on type transcript
const DetailsCard: React.FC<DetailsCardProps> = ({
  children,
  transcriptType
}) => {
let className = ".transcript-item";
  if(transcriptType){
    className = className += " transcript-card";
  } 
  return (
    <div className={className}>
      <div className="card--container">{children}</div>
    </div>
  );
};

export default DetailsCard;
