// sidebarConfig.ts
import { AppRoutes } from './routesConfig';

export type IconName = 'Home' | 'PendingActions' | 'Segment' | 'Settings' | 'AddCircle' | 'TableChart' | 'AddIcCall' | 'DeleteSweep';

export interface SubItem {
  name: string;
  href: string;
  disabled?: boolean;
}

export interface SidebarItem {
  title: string;
  icon: IconName;
  link?: string;
  collapsible?: boolean;
  defaultOpen?: boolean;  // New property
  items?: SubItem[];
}

export const sidebarConfig: SidebarItem[] = [
  {
    title: AppRoutes.home.breadcrumb,
    icon: "Home",
    link: AppRoutes.home.path,
  },
  {
    title: "Complaints Tracker",
    icon: "TableChart",
    link: AppRoutes.tracker.path,
  },
  {
    title: "Create a new complaint",
    icon: "AddCircle",
    link: AppRoutes.new.path,
  },
  {
    title: "Generate a sample call",
    icon: "AddIcCall",
    link: AppRoutes.generator.path,
  },
  {
    title: "Purge complaints",
    icon: "DeleteSweep",
    link: AppRoutes.purge.path,
  },
];