import * as React from "react";
import { useState } from "react";
import {
  Box,
  Card,
  Button,
  Chip
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { CustomerData } from "../../../interfaces";
import { getStatusColor, getPSD2Color } from "../../complaint-details/constants";
import "../ComplaintsTracker.css";
import { AgeChip } from "../../../components";
import { SummaryDialog } from "./SummaryDialog";
import { useNavigate } from "react-router-dom";

interface Props {
  data: CustomerData;
}

function getRowId(row: any) {
  return row.complaint_id;
}

export function CustomerComplaints({ data }: Props) {
  const navigate = useNavigate();


  const [open, setOpen] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState('');

  const handleOpenDialog = (summary: string) => {
    setSelectedSummary(JSON.stringify(summary));
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: "complaint_id",
      headerName: "Complaint ID",
      flex: 0.7
    }, {
      field: "date_created",
      headerName: "Created",
      flex: 0.6,
      renderCell: (params) => (
        <AgeChip dateCreated={params?.row?.complaint_date} />
      )
    }, {
      field: "complaint_status",
      headerName: "Status",
      flex: 0.9,
      renderCell: (params) => (
        <Chip size="small" label={params.row.complaint_status ?? "N/A"} color={getStatusColor(params.row.complaint_status)} />
      )
    }, {
      field: "complaint_categorisation",
      headerName: "Category",
      flex: 1.0,
      renderCell: (params) => (
        <Chip size="small" label={params.row.complaint_categorisation.Decision?.Category ?? "N/A"} />
      )
    },
    {
      field: "complaint_categorisation_psd2",
      headerName: "PSD2",
      flex: 0.4,
      renderCell: (params) => (
        <Chip size="small" label={params.row.complaint_categorisation.Decision?.PSD2 ?? "N/A"} color={getPSD2Color(params.row.complaint_categorisation.Decision?.PSD2)} />
      )
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1.4,
      renderCell: (params) => (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start" gap={2}>
          <Button
            onClick={() => navigate(`/complaints/tracker/${params.row.complaint_id}`)}
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
            }}>Open</Button>

          <Button
            onClick={() => handleOpenDialog(params.row.complaint_summary)}
            variant="outlined"
            size="small"
            style={{
              textTransform: "none"
            }}>
            Summary
          </Button>
        </Box>
      )
    }
  ];

  return (
    <>
      <Card
        variant="outlined"
        style={{
          borderRadius: "8px",
          marginTop: "16px"
        }}
        className="customerComplaintsTable">

        <DataGrid
          // loading={loading}
          disableRowSelectionOnClick
          getRowId={getRowId}
          rows={data?.open_complaints ?? []}
          columns={columns}
          initialState={{
            sorting: { sortModel: [{ field: 'date_created', sort: 'desc' }] },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[10, 20]}
          slots={{
            toolbar: GridToolbar
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
              printOptions: {
                disableToolbarButton: true
              },
              csvOptions: {
                disableToolbarButton: true
              }
            },
            columnHeaders: {
              style: {
                outline: 'none',
              },
            },
            cell: {
              style: {
                outline: 'none',
              },
            },

          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          sx={{
            boxShadow: 0,
            border: 0
          }} />
      </Card>
      <SummaryDialog open={open} selectedValue={selectedSummary} onClose={handleCloseDialog} />
    </>
  );
}
