import { Snackbar, Alert } from '@mui/material';
import React from 'react';

interface NotificationProps {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  onClose: () => void;
  autoHide?: boolean; // Optional prop to enable or disable auto-hide
}

const Notification: React.FC<NotificationProps> = ({ open, message, severity, onClose, autoHide }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide ? 5000 : null} // Conditionally set autoHideDuration
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;