import React, { useState } from "react";
import {
    Box,
    Chip,
    Card,
    CardContent,
    Typography,
    Divider,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    IconButton,
    TextField,
    Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import BulletPointList from "../../../components/bulletpoints";

interface Props {
    complaint: any;
}

const getDecisionColor = (decision: string | number) => {
    const numericDecision = typeof decision === 'string' && !isNaN(Number(decision))
        ? Number(decision)
        : decision;

    if (typeof numericDecision === 'number' && numericDecision > 0) {
        return 'warning';
    }

    const decisionString = decision
        .toString()
        .toLowerCase();

    switch (decisionString) {
        case "uphold":
            return "success";
        case "apology":
            return "info";
        case "reject":
            return "error";
        default:
            return "default";
    }
};

export const Findings = ({ complaint }: Props) => {
    const initialValue = parseInt(complaint.complaint_redress_summary?.Value, 10) || 0;
    const [value, setValue] = useState<number>(initialValue);
    const [override, setOverride] = useState<boolean>(false);

    const handleIncrease = () => {
        setValue(prev => prev + 10);
        mockApiCall(value);
    };

    const handleDecrease = () => {
        setValue(prev => (prev > 0 ? prev - 10 : 0));
        mockApiCall(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        if (!isNaN(newValue)) {
            setValue(newValue);
            mockApiCall(newValue);
        }
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride(event.target.checked);
        if (!event.target.checked) {
            setValue(initialValue);
        }
    };

    const mockApiCall = async (newValue: number) => {
        console.log(`Updating redress value to: £${newValue}`);
        // Mock API call logic here
    };

    console.log(complaint);
    return (
        <Grid container spacing={3} mt={0} justifyContent="space-between">
            {complaint.complaint_decision && complaint.complaint_decision.length > 0 ? (
                <>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <Box p={2}>
                                <Typography variant="h6" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Complaints <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ ml: "4px" }} /></Typography>
                                <Typography variant="body2" gutterBottom>
                                    The following complaint(s) have been identified:
                                </Typography>
                            </Box>
                            <Divider />
                            <CardContent>
                                <>
                                    {complaint.complaint_decision && complaint.complaint_decision.length > 0
                                        ? (complaint.complaint_decision.map((decision: any, index: number) => (
                                            <Accordion key={index} disableGutters elevation={0} square>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${index}-content`}
                                                    id={`panel${index}-header`}>
                                                    <Box display="flex" flexDirection="column" width="100%">
                                                        <Box display="flex" justifyContent="space-between">
                                                            <Typography variant="subtitle1" fontWeight={500}>
                                                                {decision.complaint_point || 'TBA'}
                                                            </Typography>
                                                            <Box>
                                                                <Chip
                                                                    sx={{ mr: 2 }}
                                                                    label={'Decision: ' + (decision.Decision || 'TBA')}
                                                                    color={getDecisionColor(decision.Decision || '')} />
                                                                {/* <Chip
                                                                    label={'Redress: ' + (decision.Redress?.Decision || 'TBA')}
                                                                    color={getDecisionColor(decision.Redress?.Decision || '')} /> */}
                                                                <Chip
                                                                    label={
                                                                        'Redress: ' +
                                                                        (decision.Redress?.Decision != null
                                                                            ? (() => {
                                                                                const decisionValue = decision.Redress.Decision;
                                                                                const numericDecision = typeof decisionValue === 'string' && !isNaN(Number(decisionValue))
                                                                                    ? Number(decisionValue)
                                                                                    : decisionValue;

                                                                                if (typeof numericDecision === 'number' && numericDecision > 0) {
                                                                                    return `£${numericDecision}`;
                                                                                }

                                                                                return decisionValue.toString();
                                                                            })()
                                                                            : 'TBA')
                                                                    }
                                                                    color={getDecisionColor(decision.Redress?.Decision || '')}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        width="100%"
                                                        px={2}
                                                        mb={2}
                                                        borderLeft={1}>
                                                        <Box mb={2}>
                                                            <Typography variant="subtitle2">Analysis:</Typography>
                                                            <Typography variant="body2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
                                                                {decision.Analysis || 'TBA'}
                                                            </Typography>
                                                            {/* 
                                                            {decision.Analysis
                                                                ? <BulletPointList paragraph={decision.Analysis || 'TBA'} listStyle="bullet" />
                                                                : 'TBA'} */}

                                                        </Box>
                                                        <Divider variant="middle" />
                                                        <Box mt={2}>
                                                            <Typography variant="subtitle2">Redress Rationale:</Typography>
                                                            <Typography variant="body2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
                                                                {decision.Redress.Analysis || 'TBA'}
                                                            </Typography>
                                                            {/*                                                             
                                                            {decision.Redress?.Analysis
                                                                ? <BulletPointList paragraph={decision.Redress.Analysis || 'TBA'} listStyle="bullet" />
                                                                : 'TBA'} */}
                                                        </Box>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        )))
                                        : (
                                            <Typography>No complaint decisions available.</Typography>
                                        )}
                                </>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <Box p={2}>
                                <Typography variant="h6" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Summary Redress <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ ml: "4px" }} /></Typography>
                                <Typography variant="body2" gutterBottom>
                                    The following summary redress value and rationale have been identified:
                                </Typography>
                            </Box>
                            <Divider />
                            <CardContent>

                                <>
                                    {complaint.complaint_redress_summary ? (
                                        <Grid container spacing={3}>
                                            <Grid item xs={8}>
                                                <Typography variant="body2" gutterBottom sx={{ whiteSpace: "pre-wrap" }}>
                                                    {complaint.complaint_redress_summary.Summary || 'TBA'}
                                                </Typography>

                                                {/* <BulletPointList paragraph={complaint.complaint_redress_summary.Summary || 'TBA'} listStyle="bullet" /> */}


                                            </Grid>
                                            <Grid item xs={4}>

                                                <Card variant="outlined" style={{ padding: '8px' }}>
                                                    <CardContent>
                                                        <Typography variant="h6" gutterBottom>
                                                            Summary Redress Value
                                                        </Typography>
                                                        <Typography variant="body2" gutterBottom>
                                                            Adjust the redress value as necessary.
                                                        </Typography>
                                                        <Typography variant="h1" gutterBottom align={"center"}>
                                                            £{value || 'TBA'}
                                                        </Typography>
                                                        <Divider />

                                                        <Box mt={2}>

                                                            <Grid component="label" container alignItems="center" spacing={1} justifyContent={"center"}>
                                                                <Grid item>
                                                                    <Typography variant="body2">
                                                                        AI Suggested
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch checked={override} onChange={handleSwitchChange} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="body2">
                                                                        Handler Override
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                        </Box>
                                                        <Box display="flex" alignItems="center" mt={1}>
                                                            <IconButton onClick={handleDecrease} disabled={!override}>
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <TextField
                                                                value={value}
                                                                onChange={handleInputChange}
                                                                disabled={!override}
                                                                type="number"
                                                                inputProps={{
                                                                    min: 0, style: {
                                                                        textAlign: 'center',
                                                                        fontSize: '1.5em',
                                                                        fontWeight: 600,
                                                                        border: 0,
                                                                        padding: 0,
                                                                    }
                                                                }}

                                                            />
                                                            <IconButton onClick={handleIncrease} disabled={!override}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </CardContent>
                                                </Card>

                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Alert severity="info">
                                            <Typography variant="body2" gutterBottom>
                                                No redress summary available.
                                            </Typography>
                                        </Alert>
                                    )}
                                </>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <Typography variant="body2" gutterBottom>
                            Results not yet available. Please check back later.
                        </Typography>
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};